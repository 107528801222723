import React, { useEffect, useContext, useRef, useState, Fragment } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import ImagePreview from '../../components/common/ImagePreview';
import Sidebar from '../../components/common/ArticleSidebar'
import SocialMedia from '../../components/common/SocialMedia'
import empty from '../../assets/img/icons/empty.png'
import ogImg from '../../assets/img/pages/newsletter/adipec-2024/virsat-adipec-2024.jpg'

const VirsatAdipec2024 = ({location}) => {
  const [pageTitle] = useState('VIRSAT Showcases Innovative Virtual Reality Safety Training Solutions at ADIPEC 2024')
  const state = useContext(GlobalStateContext);
  let container = useRef(null);
  const slug = useState(location.pathname.split('/')[2]);
  let endDiv = useRef(null);
  const [endDivPosition, setEndDivPosition] = useState(0);

  const [showImgPrev,setShowImgPrev]=useState(false)
  const [activeImg,setActiveImg]=useState(empty)

  const handeleActiveImg = (img)=> {
    setActiveImg(img)
    setShowImgPrev(true)
  }

  const updatePosition = () => {
    setEndDivPosition(endDiv.current.getBoundingClientRect().top)
  };

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);
    
    return () => window.removeEventListener("scroll", updatePosition);
  }, [endDivPosition]);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Fragment>
      <ImagePreview img={activeImg} orientation="landscape" onClick={()=>setShowImgPrev(!showImgPrev)} show={showImgPrev}/>
      <Layout>
        <Seo
          title={pageTitle}
          description='Virtual Reality Safety Training (VIRSAT) is proud to participate in ADIPEC for the third consecutive year, where they showcased their state-of-art Virtual Reality innovative products.'
          image={ogImg}
          ogType='article'
        />
        <PageBanner title='Articles' useDiv={true}/>
        <section
          className='main-container single-article hide-container'
          ref={container}
        >
          <div className='row less-mar bottom-pad'>
            <SocialMedia 
              pageUrl={location.href} 
              pageTitle={pageTitle}
              endDivPosition={endDivPosition}
            />
            {/* Article */}
            <article className='column article-details'>
              <header>
                <h1 className="main-title small primary-color">
                  <strong>VIRSAT Showcases Innovative Virtual Reality Safety Training Solutions at ADIPEC 2024</strong>
                </h1>
                <div className='author-date'>
                  <span></span>November 24, 2024
                  <span className='spacer'>|</span>5 minutes read
                </div>
              </header>
              <video muted="1" autoPlay="1" controls="1" width="100%" height="100%">
                <source src="https://secure.virsat.com/video/adipec-2024-video.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
              <p className="justify">
              <strong>Virtual Reality Safety Training (VIRSAT)</strong> is proud to participate in <a href="https://www.adipec.com/">ADIPEC</a> for the third consecutive year, where they showcased their state-of-art Virtual Reality innovative products.
              </p>

              <div className="flex gap-2">
                <p className="flex-1">
                VIRSAT's core business is helping Oil & Gas (O&G) companies address their onsite safety requirements by using innovative methods and techniques to deliver training to workers, regardless of their profession or location within the organization. VIRSAT focuses on developing safety training software, simulation systems, and games using Virtual Reality (VR) and 3D technologies. It is one of the few companies worldwide to develop the Life-Saving Rules Serious Games based on the standards of the International Association of Oil and Gas Producers (IOGP) and a long history of incident investigations and findings worldwide.
                </p>
                <div className="flex-1" style={{marginTop:'20px'}}>
                  <div className='cursor-default'>
                    <StaticImage
                      src='../../assets/img/pages/newsletter/adipec-2024/vr.jpg'
                      alt='Virtual reality'
                      title='Virtual reality'
                      quality="100"
                      objectFit
                      className='gatsby-image'
                    />
                  </div>
                </div>
              </div>

              <p className="mar-bottom">As a leading provider of Virtual Reality safety training for the Oil & Gas industry, VIRSAT participated in ADIPEC to showcase its unique products, including <strong>Life-Saving RulesAWARE®</strong>, <strong>Life-Saving RulesPRO®</strong>, <strong>Life-Saving Rules Graded Road DrivingPRO</strong>, and the <strong>Graded Road Driving Simulator</strong>. While the <strong>Life-Saving RulesAWARE®</strong> is designed to give trainees the opportunity to learn and review basic health and safety rules as detailed by IOGP and other leading O&G producers, <strong>Life-Saving RulesPRO®</strong> allows the drivers to assume the role of a manager or supervisor, enabling them to follow workers and intervene when a safety violation is observed. </p>
              
              <div>
                <StaticImage
                  src='../../assets/img/pages/newsletter/adipec-2024/virsat-vr-games.jpg'
                  alt='VIRSAT VR Games'
                  title='VIRSAT VR Games'
                  quality="100"
                  objectFit
                  className='gatsby-image'
                />
              </div>

              <div className="flex gap-2">
                <p className="flex-1">
                The <strong>Graded Road Driving Simulator</strong> completes the training package by providing trainees with the opportunity to apply the knowledge they have gained from VR games by practically driving in a simulated environment. The simulator system replicates realistic driving scenarios, featuring a graded road surface that mirrors the challenges faced in actual operations. Trainees are immersed in a 3D visual environment with seat movements, vibrations, and directional sounds, allowing them to experience driving dynamics effectively. 
                </p>
                <div className="flex-1" style={{marginTop:'20px'}}>
                  <div>
                    <StaticImage
                      src='../../assets/img/pages/newsletter/adipec-2024/virsat-driving-simulator.jpg'
                      alt='VIRSAT Driving Simulator'
                      title='VIRSAT Driving Simulator'
                      quality="100"
                      objectFit
                      className='gatsby-image'
                    />
                  </div>
                </div>
              </div>
              <br/><br/>
              <div className="flex gap-2">
                <div>
                  <StaticImage
                    src='../../assets/img/pages/newsletter/adipec-2024/overspeeding.jpg'
                    alt='Over Speeding'
                    title='Over Speeding'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
                </div>
                <div>
                  <StaticImage
                    src='../../assets/img/pages/newsletter/adipec-2024/overtaking.jpg'
                    alt='Overtaking'
                    title='Overtaking'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
                </div>
              </div>
              <br/>
              <div className="flex gap-2">
                <div>
                  <StaticImage
                    src='../../assets/img/pages/newsletter/adipec-2024/driving-through-dust.jpg'
                    alt='Driving through dust'
                    title='Driving through dust'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
                </div>
                <div>
                  <StaticImage
                    src='../../assets/img/pages/newsletter/adipec-2024/light-vehicle-roll-over-prevention.jpg'
                    alt='Light Vehicle Rollever Prevention'
                    title='Light Vehicle Rollever Prevention'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
                </div>
              </div>

              <p>
              At <strong>ADIPEC</strong>, customers had the chance to experience the VR Training firsthand and see how this new technology can open new possibilities and add value to their workforce. 
              </p>
              <div style={{height:'1px','width':'1px'}} ref={endDiv}/>
            </article>
            {/* End: Article */}

            {/* Sidebar */}
            <Sidebar slug={slug} endDivPosition={endDivPosition} type="news"/>
          </div>
        </section>
      </Layout>
    </Fragment>
  )
}

export default VirsatAdipec2024